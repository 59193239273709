import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    component: () => import('../views/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue'),
      },
      {
        path: '/product/short-speech-transcription',
        name: 'SST',
        component: () => import('../views/SST.vue'),
      },
      {
        path: '/product/real-time-transcription',
        name: 'RTT',
        component: () => import('../views/RTT.vue'),
      },
      // {
      //   path: '/product/tse',
      //   name: 'File',
      //   component: () => import('../views/File.vue'),
      // },
      {
        path: '/product/tese',
        name: 'File',
        component: () => import('../views/FileE.vue'),
      },
      {
        path: '/product/languages',
        name: 'Languages',
        component: () => import('../views/Languages.vue'),
      },
      // {
      //   path: '/product/translation',
      //   name: 'Translation',
      //   component: () => import('../views/Translation.vue'),
      // },
      {
        path: '/product/on-device',
        name: 'OnDevice',
        component: () => import('../views/OnDevice.vue'),
      },
      {
        path: '/product/on-prem',
        name: 'OnPrem',
        component: () => import('../views/OnPrem.vue'),
      },
      {
        path: '/product/cloud-api',
        name: 'APICloud',
        component: () => import('../views/APICloud.vue'),
      },
      {
        path: '/features',
        name: 'Features',
        component: () => import('../views/Features.vue'),
      },
      {
        path: '/contact-us',
        name: 'Contact',
        component: () => import('../views/ContactUs.vue'),
      },
      {
        path: '/documentation',
        name: 'Help',
        component: () => import('../components/Help.vue'),
      },
      {
        path: '/security',
        name: 'Security',
        component: () => import('../views/Security.vue'),
      },
      {
        path: '/isms',
        name: 'ISMS',
        component: () => import('../views/ISO27001.vue'),
      },
      {
        path: '/pricing',
        name: 'Pricing',
        component: () => import('../views/Pricing.vue')
      },
      {
        path: '/law',
        name: 'Law',
        component: () => import('../views/Law.vue'),
      }
    ]
  },
  {
    path: '/backend',
    name: 'Backend',
    component: () => import('../views/Backend.vue'),
    children: [
      {
        path: '',
        name: 'MainContainer',
        component: () => import('../components/backend/MainContainer.vue'),
        children: [
          {
            path: '/backend/binding',
            name: 'Binding',
            component: () => import('../components/backend/CreditCard.vue'),
          },
          {
            path: '',
            name: 'Used',
            component: () => import('../components/backend/Used.vue')
          },
          {
            path: '/backend/engine',
            name: 'Engine',
            component: () => import('../components/backend/Engine.vue')
          },
          {
            path: '/backend/informations',
            name: 'ProjectInfos',
            component: () => import('../components/backend/ProjectInfos.vue')
          },
          {
            path: '/backend/term',
            name: 'Term',
            component: () => import('../components/backend/Term.vue')
          },
          {
            path: '/backend/costdetails',
            name: 'Cost',
            component: () => import('../components/backend/Cost.vue')
          },
          {
            path: '/backend/usercenter',
            name: 'UserCenter',
            component: () => import('../components/backend/UserCenter.vue')
          }
        ]
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../components/backend/Login.vue')
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../components/backend/Register.vue')
      },
      {
        path: '/error',
        name: 'Error',
        component: () => import('../components/backend/Error.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  // base: '/pre_dolphinvoice/',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
